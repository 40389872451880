import { Grid32, List32 } from '@carbon/icons-react';
import { FlexGrid } from '@carbon/react';
import {
  Button,
  ClickableTile,
  Column,
  ContentSwitcher,
  OnChangeData,
  Row,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import sortData from '../../lib/tableSort';
import {
  getSessionStorageItem,
  manageFiltersFromStorage,
  setSessionStorageItem,
} from '../../lib/utils';
import GatewaysCard from './GatewaysCard/GatewaysCard';
import GatewaysTable from './GatewaysTable/GatewaysTable';
import Error500Card from '../../components/ErrorState/Error500Card';

import './GatewaysContainer.scss';

import {
  AppliedFilter,
  Cloud,
  DeploymentEnvironment,
  Error500Type,
  Gateway,
  GatewayCustomData,
  Location,
  NetworkSegment,
  ResourceGroup,
} from '../../models/master';

import { AxiosError } from 'axios';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import Error403Card from '../../components/ErrorState/Error403Card';
import images from '../../images/images';
import {
  HealthStatusType,
  getHealthStatusConfig,
} from '../../components/GatewayStatus/config';
import { VisibilityFlags } from '../../lib/enums';
import useAnalytics from '../../lib/useAnalytics';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';
import DeployGateway from '../DeployGateway/DeployGateway';
import ConnectGatewayCluster from '../../components/ConnectGatewayCluster/ConnectGatewayCluster';
import { useDeploymentEnvsData } from '../../hooks/useDeploymentEnvs';
import { useLocations } from '../../hooks/useLocations';
import { useCloudsData } from '../../hooks/useClouds';
import { useNetworksegments } from '../../hooks/useNetworksegment';
import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import { useGateways } from '../../hooks/useGateways';

type View = 'table' | 'card';

let defaultPermissionMap = {
  gateways: true,
  resourceGroup: true,
  location: true,
  networkSegment: true,
  cloud: true,
  deploymentEnvironment: true,
};

let defaultError500Map = {
  gateways: false,
};

export interface GatewayType {
  resource_id: string;
  name: string;
}

const GatewaysContainer = () => {
  const { t } = useTranslation('gatewaysContainer');

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);

  const [isRefetchingGateways, setIsRefetchingGateways] = useState(false);
  const [filteredData, setFilteredData] = useState<GatewayCustomData[] | []>(
    []
  );
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const [showDeployGateway, setOpenDeployGateway] = useState(false);
  const [gatewayToBeDeployed, setDeployGatewayData] = useState(null);
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [existingGatewayNames, setExistingGatewayNames] = useState<string[]>(
    []
  );
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const { pageViewed, trackButtonClicked } = useAnalytics();
  const [tileClicked, setTileClicked] = useState({
    clicked: true,
    type: VisibilityFlags.MANAGED,
  });

  const [showConnectClusterTearSheet, setShowConnectClusterTearSheet] =
    useState(false);
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(null);
  const visibilityFilterFlag =
    getSessionStorageItem('MCNM_VISIBILITY_FLAG') || VisibilityFlags.MANAGED;

  // Queries gateways
  const {
    data: gateways,
    isLoading: loadingGateways,
    error: gatewayError,
    isError: isGatewayError,
    refetch: refetchGateways,
    isRefetching: refetchingGateways,
  } = useGateways(VisibilityFlags.ALL);
  if (isGatewayError) {
    const error = gatewayError as AxiosError;
    defaultPermissionMap.gateways =
      error?.response?.status === 403 ? false : true;

    defaultError500Map.gateways =
      error?.response!?.status >= 500 ? true : false;
  }

  // Query Deployment Environments

  const {
    data: deploymentEnvironments,
    isLoading: loadingDeploymentEnvironments,
    refetch: refetchDeploymentEnvs,
    error: deploymentEnvError,
    isError: isDeploymentEnvError,
  } = useDeploymentEnvsData(VisibilityFlags.ALL);

  if (isDeploymentEnvError) {
    const error = deploymentEnvError as AxiosError;
    defaultPermissionMap.deploymentEnvironment =
      error?.response?.status === 403 ? false : true;
  }

  // Query clouds

  const {
    data: clouds,
    isLoading: loadingClouds,
    error: cloudError,
    refetch: refetchClouds,
    isError: isCloudError,
  } = useCloudsData({
    refetchOnWindowFocus: false,
  });

  if (isCloudError) {
    const error = cloudError as AxiosError;
    defaultPermissionMap.cloud = error?.response?.status === 403 ? false : true;
  }

  // Queries locations

  const {
    data: locations,
    isLoading: loadingLocations,
    error: locationError,
    refetch: refetchLocations,
    isError: isLocationError,
  } = useLocations(VisibilityFlags.ALL);
  if (isLocationError) {
    const error = locationError as AxiosError;
    defaultPermissionMap.location =
      error?.response?.status === 403 ? false : true;
  }

  // Query Network Segments

  const {
    data: networkSegments = [],
    isLoading: loadingNetworkSegments,
    error: networkSegmentError,
    refetch: refetchNetworkSegments,
    isError: isNetworkSegmantError,
  } = useNetworksegments(VisibilityFlags.ALL, {
    refetchOnWindowFocus: false,
    view: VisibilityFlags.ALL,
  });

  if (isNetworkSegmantError) {
    const error = networkSegmentError as AxiosError;
    defaultPermissionMap.networkSegment =
      error?.response?.status === 403 ? false : true;
  }

  // Query Resource Group

  const {
    data: resourceGroups,
    isLoading: loadingResourceGroups,
    error: resourceGroupError,
    refetch: refetchResourceGroups,
    isError: isResourceGroupError,
  } = useResourceGroupsData({
    refetchOnWindowFocus: false,
  });

  if (isResourceGroupError) {
    const error = resourceGroupError as AxiosError;
    defaultPermissionMap.resourceGroup =
      error?.response?.status === 403 ? false : true;
  }

  useEffect(() => {
    if (getSessionStorageItem('MCNM_VISIBILITY_FLAG') === VisibilityFlags.ALL) {
      setTileClicked(prevState => ({
        ...prevState,
        clicked: false,
        type: VisibilityFlags.ALL,
      }));
    } else {
      if (
        getSessionStorageItem('MCNM_VISIBILITY_FLAG') ===
        VisibilityFlags.UNMANAGED
      ) {
        setTileClicked(prevState => ({
          ...prevState,
          clicked: true,
          type: VisibilityFlags.UNMANAGED,
        }));
      } else {
        setTileClicked(prevState => ({
          ...prevState,
          clicked: true,
          type: VisibilityFlags.MANAGED,
        }));
      }
    }
    pageViewed('Gateways');
    refreshData();
  }, []);

  useEffect(() => {
    !refetchingGateways && setIsRefetchingGateways(false);
  }, [refetchingGateways]);

  const leftInlineFilters: any = [
    {
      key: 'view',
      label: t('view'),
      type: '',
      values: [
        {
          value: VisibilityFlags.ALL,
          label: t('all'),
        },
        {
          value: VisibilityFlags.MANAGED,
          label: t('managed'),
        },
        {
          value: VisibilityFlags.UNMANAGED,
          label: t('unmanaged'),
        },
      ],
      filterCallback: (e: OnChangeData<any>) => {
        if (e.selectedItem.value !== visibilityFilterFlag) {
          applyVisibilityFlag(e.selectedItem?.value);
          e.selectedItem.value === VisibilityFlags.ALL &&
            setTileClicked(prevState => ({
              ...prevState,
              clicked: false,
              type: VisibilityFlags.ALL,
            }));
          e.selectedItem.value !== VisibilityFlags.ALL &&
            setTileClicked(prevState => ({
              ...prevState,
              clicked: true,
              type: e.selectedItem.value,
            }));
        }
      },
    },
  ];

  const applyVisibilityFlag = (flag: string) => {
    setSessionStorageItem('MCNM_VISIBILITY_FLAG', flag);
  };

  const refreshData = () => {
    setSortKey('');
    setSortDirection('NONE');
    setFilterApplied(manageFiltersFromStorage());
    setIsRefetchingGateways(true);
    defaultError500Map = {
      ...defaultError500Map,
      gateways: false,
    };
    defaultPermissionMap = {
      ...defaultPermissionMap,
      gateways: true,
      resourceGroup: true,
      location: true,
      networkSegment: true,
      cloud: true,
      deploymentEnvironment: true,
    };
    refetchGateways();
    refetchDeploymentEnvs();
    refetchClouds();
    refetchLocations();
    refetchNetworkSegments();
    refetchResourceGroups();
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (gatewaysCustomData) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
      } else {
        setSortDirection('DESC');
      }
    }
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={gatewaysCustomData}
        filteredDataCallback={data => {
          setFilteredData(data as GatewayCustomData[] | []);
        }}
        filteredData={
          gatewaysCustomData
            ? filterApplied?.length > 0
              ? filteredData
              : gatewaysCustomData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        persistFilter={true}
        filters={getGatewayFilters(gatewaysCustomData) as any}
        onRefresh={() => {
          refreshData();
        }}
        leftInlineFilters={leftInlineFilters}
        visibilityFlag={visibilityFilterFlag}
      />
    );
  };
  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const refreshGateways = (isRefresh?: boolean) => {
    isRefresh && refreshData();
  };

  const getGatewayFilters = (gatewaysData: GatewayCustomData[] | null) => [
    {
      key: 'type_name',
      label: t('filters.type'),
      placeHolderText: t('filters.placeHolderText.type'),
      type: 'multi',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.type_name && gateway?.type_name !== undefined
              )
              .map(gateway => gateway?.type_name)
          )
        ),
      ],
    },
    {
      key: 'health_status',
      label: t('filters.health'),
      placeHolderText: t('filters.placeHolderText.generic'),
      type: 'single',
      translationReqd: true,
      translationData: {
        operational: t('operational'),
        critical: t('critical'),
        minor: t('minor'),
        notdeployed: t('notdeployed'),
        pending: t('pending'),
        errored: t('errored'),
        unreachable: t('unreachable'),
      },
      hasIcon: true,
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.health_status && gateway?.health_status !== undefined
              )
              .map(gateway => gateway.health_status)
          )
        ).map(status => {
          const healthConfig = getHealthStatusConfig(
            t,
            status as HealthStatusType
          );
          return {
            icon: healthConfig?.icon ?? null,
            label: status,
          };
        }),
      ],
    },
    {
      key: 'location_name',
      label: t('filters.location'),
      placeHolderText: t('filters.placeHolderText.generic'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.location_name && gateway?.location_name !== undefined
              )
              .map(gateway => gateway?.location_name)
          )
        ),
      ],
    },
    {
      key: 'network_segment_name',
      label: t('filters.networkSegment'),
      placeHolderText: t('filters.placeHolderText.networkSegment'),
      type: 'multi',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.network_segment_name &&
                  gateway?.network_segment_name !== undefined
              )
              .map(gateway => gateway?.network_segment_name)
          )
        ),
      ],
    },
  ];

  const getResourceGroupName = (
    id: string,
    resourceGroups: ResourceGroup[] | null
  ) => {
    const resourceGroup =
      id &&
      Array.isArray(resourceGroups) &&
      resourceGroups.find(resourceGroup => resourceGroup.resource_id === id);
    return resourceGroup ? resourceGroup.name : '';
  };

  const formatGatewayData = () => {
    let customisedGatewayData: GatewayCustomData[] = [],
      resourceGroupName: string,
      networkSegment: NetworkSegment | null,
      deployment: DeploymentEnvironment | undefined,
      location: Location | null = null,
      cloud: Cloud | null = null;

    if (gateways && Array.isArray(gateways)) {
      for (const gateway of gateways) {
        // Resource Group Name

        resourceGroupName = getResourceGroupName(
          gateway.resource_group_id,
          resourceGroups
        );

        // Network Segment

        networkSegment =
          networkSegments && Array.isArray(networkSegments)
            ? networkSegments.find(
                nwSegment =>
                  nwSegment?.resource_id === gateway?.network_segment_id
              )
            : null;

        // Deployment Environment

        deployment =
          deploymentEnvironments && Array.isArray(deploymentEnvironments)
            ? deploymentEnvironments?.find(
                (deplEnv: DeploymentEnvironment) =>
                  deplEnv.resource_id === gateway?.deployed_in_depl_env_id
              )
            : null;

        //Cloud

        cloud =
          clouds && Array.isArray(clouds)
            ? clouds.find(
                (cloud: Cloud) => cloud.resource_id === gateway.cloud_id
              ) ?? null
            : null;

        // Location
        location =
          locations?.locationsData && Array.isArray(locations?.locationsData)
            ? locations?.locationsData.find(
                (location: Location) =>
                  location?.resource_id === gateway?.location_id
              ) ?? null
            : null;

        customisedGatewayData.push({
          ...gateway,
          resourceGroupsPermission: defaultPermissionMap['resourceGroup'],
          locationPermission: defaultPermissionMap['location'],
          cloudPermission: defaultPermissionMap['cloud'],
          networkSegmentPermission: defaultPermissionMap['networkSegment'],
          deploymentEnvironmentPermission:
            defaultPermissionMap['deploymentEnvironment'],
          resource_name: resourceGroupName,
          location_name: location?.name || '',
          cloud_name: cloud?.name || '',
          partition_name: gateway?.namespace_name || '',
          deployment_env_name: deployment?.name || '',
          network_segment_name: networkSegment?.name ?? '',
          network_segment_id: networkSegment?.resource_id ?? '',
          type_name: gateway.subtype
            ? t(`gatewayTypes.${gateway.subtype}`)
            : '',
          environment_partition_field: `${deployment?.name || ''}/${
            gateway?.namespace_name || ''
          }`,
          cloud_location_field: `${cloud?.name || ''}/${location?.name || ''}`,
        });
      }
    }

    return customisedGatewayData;
  };

  const formattedGateways = useMemo(
    () => formatGatewayData(),
    [
      gateways,
      locations?.locationsData,
      deploymentEnvironments,
      clouds,
      networkSegments,
      resourceGroups,
    ]
  );

  const getVisibilityFilteredGateways = (
    flag: string,
    gateWaysList: GatewayCustomData[] | null
  ) => {
    switch (flag.toLowerCase()) {
      case 'all':
        return gateWaysList;
      case 'managed':
        return Array.isArray(gateWaysList)
          ? gateWaysList?.filter(gateway => !gateway?.unmanaged)
          : [];

      case 'unmanaged':
        return Array.isArray(gateWaysList)
          ? gateWaysList?.filter(gateway => gateway?.unmanaged)
          : [];

      default:
        return [];
    }
  };

  const gatewaysCustomData = useMemo(
    () =>
      getVisibilityFilteredGateways(visibilityFilterFlag, formattedGateways),
    [visibilityFilterFlag, formattedGateways]
  );

  const getSortedGatewayData = (
    gatewaysCustomData: GatewayCustomData[] | null
  ) => {
    let gateways =
      filterApplied?.length > 0 ? filteredData : gatewaysCustomData;
    if (sortDirection === 'ASC') {
      return Array.isArray(gateways)
        ? gateways.sort((a, b) =>
            a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
              ? 1
              : -1
          )
        : [];
    } else if (sortDirection === 'DESC') {
      return Array.isArray(gateways)
        ? gateways.sort((a, b) =>
            a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
              ? 1
              : -1
          )
        : [];
    } else {
      return gateways;
    }
  };

  const sortedGatewaysData = useMemo(
    () => getSortedGatewayData(gatewaysCustomData),
    [gatewaysCustomData, sortDirection, filterApplied]
  );

  const getGatewayCount = () => {
    let managedCount = 0;
    let unmanagedCount = 0;

    if (Array.isArray(gateways)) {
      managedCount =
        gateways?.filter(gateway => !gateway?.unmanaged).length ?? 0;
      unmanagedCount =
        gateways?.filter(gateway => gateway?.unmanaged).length ?? 0;
    }

    return { managedCount, unmanagedCount };
  };

  const gatewayCount = useMemo(() => getGatewayCount(), [gateways]);

  const learnMore = {
    url:
      navigator.language === 'fr' ||
      navigator.language === 'fr-FR' ||
      navigator.language === 'fr-BE' ||
      navigator.language === 'fr-CA' ||
      navigator.language === 'fr-CH'
        ? 'https://ibm.biz/mesh-gateways-fr'
        : 'https://ibm.biz/mesh-gateways',
    label: t('learnMore'),
  };

  const loading =
    loadingGateways ||
    isRefetchingGateways ||
    loadingDeploymentEnvironments ||
    loadingClouds ||
    loadingLocations ||
    loadingNetworkSegments ||
    loadingResourceGroups;

  return (
    <div className='gateway-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        learnMoreLink={learnMore}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='gateway-switcher'>
          <div className='tile-switch-section'>
            <>
              {loading ? (
                <div className='skeleton-clickable-tile-container'>
                  <SkeletonPlaceholder className={'clickable-tile-skeleton'} />
                  <SkeletonPlaceholder className={'clickable-tile-skeleton'} />
                </div>
              ) : (
                <>
                  <ClickableTile
                    id='clickable-tile-unmanaged-gateways'
                    className={`gateway-clickable-tile ${
                      tileClicked.clicked &&
                      tileClicked.type === VisibilityFlags.UNMANAGED
                        ? 'clicked'
                        : 'unClicked'
                    }`}
                    visited={
                      tileClicked.clicked &&
                      tileClicked.type === VisibilityFlags.UNMANAGED
                    }
                    onClick={() => {
                      if (
                        tileClicked.clicked &&
                        tileClicked.type === VisibilityFlags.UNMANAGED
                      ) {
                        setTileClicked(prevState => ({
                          ...prevState,
                          clicked: false,
                          type: VisibilityFlags.ALL,
                        }));
                        applyVisibilityFlag(VisibilityFlags.ALL);
                      } else {
                        setTileClicked(prevState => ({
                          ...prevState,
                          clicked: true,
                          type: VisibilityFlags.UNMANAGED,
                        }));
                        applyVisibilityFlag(VisibilityFlags.UNMANAGED);
                      }
                    }}
                  >
                    <div className='tile-content'>
                      <div className='tile-title'>
                        <span className='title'>
                          {t('tileSwitch.unmanagedGateway')}
                        </span>
                        <div className='selected-icon'>
                          {images.selectedTickIcon()}
                        </div>
                      </div>
                      <span className='tile-resource-count'>
                        {gatewayCount.unmanagedCount}
                      </span>
                    </div>
                  </ClickableTile>
                  <ClickableTile
                    id='clickable-tile-managed-gateways'
                    className={`gateway-clickable-tile ${
                      tileClicked.clicked &&
                      tileClicked.type === VisibilityFlags.MANAGED
                        ? 'clicked'
                        : 'unClicked'
                    }`}
                    visited={
                      tileClicked.clicked &&
                      tileClicked.type === VisibilityFlags.MANAGED
                    }
                    onClick={() => {
                      if (
                        tileClicked.clicked &&
                        tileClicked.type === VisibilityFlags.MANAGED
                      ) {
                        setTileClicked(prevState => ({
                          ...prevState,
                          clicked: false,
                          type: VisibilityFlags.ALL,
                        }));
                        applyVisibilityFlag(VisibilityFlags.ALL);
                      } else {
                        setTileClicked(prevState => ({
                          ...prevState,
                          clicked: true,
                          type: VisibilityFlags.MANAGED,
                        }));
                        applyVisibilityFlag(VisibilityFlags.MANAGED);
                      }
                    }}
                  >
                    <div className='tile-content'>
                      <div className='tile-title'>
                        <span className='title'>
                          {t('tileSwitch.managedGateway')}
                        </span>
                        <div className='selected-icon'>
                          {images.selectedTickIcon()}
                        </div>
                      </div>
                      <span className='tile-resource-count'>
                        {gatewayCount.managedCount}
                      </span>
                    </div>
                  </ClickableTile>
                </>
              )}
            </>
          </div>
          {view !== 'table' ? (
            <>
              {loading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='gateway-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <GatewaysTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              onPageChange={pageData => setPageChange(pageData)}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              rows={
                Array.isArray(gatewaysCustomData)
                  ? filterApplied?.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(gatewaysCustomData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                gatewaysCustomData
                  ? filterApplied?.length > 0
                    ? filteredData?.length
                    : gatewaysCustomData?.length
                  : 0
              }
              filteredDataSet={
                gatewaysCustomData
                  ? filterApplied?.length > 0
                    ? filteredData
                    : gatewaysCustomData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as GatewayCustomData[] | []);
                setPageNumber(1);
              }}
              data={gatewaysCustomData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              persistFilter={true}
              filters={getGatewayFilters(gatewaysCustomData)}
              onRefresh={() => {
                refreshGateways(true);
              }}
              leftInlineFilters={leftInlineFilters}
              visibilityFlag={visibilityFilterFlag}
              resourceGroupsList={resourceGroupsData}
              error403Flag={!permissionMap['gateways']}
              error500Flag={!!error500}
              openDeployGateway={data => {
                setOpenDeployGateway(true);
                setDeployGatewayData(data);
              }}
              openConnectGatewayClusterTearSheet={(gateway: Gateway) => {
                setShowConnectClusterTearSheet(!showConnectClusterTearSheet);
                setSelectedGateway(gateway);
              }}
              isDataLoading={loading}
            />
          ) : !defaultPermissionMap['gateways'] ? (
            <Error403Card />
          ) : defaultError500Map.gateways ? (
            <Error500Card />
          ) : loading ? (
            <div>
              {!gatewaysCustomData && renderFilter()}

              <Row className='skeleton-card-view'>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
              </Row>
            </div>
          ) : (
            <div>
              {sortedGatewaysData && sortedGatewaysData?.length >= 0
                ? renderFilter()
                : null}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {sortedGatewaysData ? (
                      (filterApplied?.length > 0
                        ? filteredData
                        : sortedGatewaysData
                      )?.length > 0 ? (
                        (filterApplied?.length > 0
                          ? filteredData
                          : sortedGatewaysData
                        ).map(gateway => (
                          <Column
                            lg={4}
                            md={4}
                            className='gateway-resource-card'
                          >
                            <GatewaysCard
                              name={gateway.name}
                              unmanaged={gateway.unmanaged}
                              id={gateway.resource_id}
                              health_status={gateway.health_status}
                              status={gateway.procedural_status}
                              type={gateway.type}
                              subType={gateway.subtype}
                              location={gateway?.location_name}
                              cloud={gateway?.cloud_name}
                              resourceGroup={
                                getResourceGroupName(
                                  gateway.resource_group_id,
                                  resourceGroups
                                ).length > 0
                                  ? getResourceGroupName(
                                      gateway.resource_group_id,
                                      resourceGroups
                                    )
                                  : '—'
                              }
                              deploymentEnvironment={
                                gateway.deployment_env_name
                              }
                              partition={gateway.partition_name}
                              cloud_id={gateway.cloud_id}
                              location_id={gateway.location_id}
                              partition_id={
                                gateway?.deployed_in_partition_id || ''
                              }
                              environment_id={
                                gateway?.deployed_in_depl_env_id || ''
                              }
                              publicIp={gateway.public_ip}
                              path={`/gatewayDetails?gatewayId=${gateway.resource_id}`}
                              updated_at={gateway.updated_at}
                              locationPermission={gateway.locationPermission}
                              networkSegmentPermission={
                                gateway.networkSegmentPermission
                              }
                              cloudPermission={gateway.cloudPermission}
                              deploymentEnvironmentPermission={
                                gateway.deploymentEnvironmentPermission
                              }
                              network_segment_name={
                                gateway.network_segment_name
                              }
                              network_segment_id={gateway.network_segment_id}
                              version={gateway.intended_version}
                              lifeCycleState={gateway?.lifecycle_state || ''}
                              proceduralActivity={
                                gateway?.procedural_activity || ''
                              }
                              procedure={gateway?.procedure || ''}
                              openDeployGateway={data => {
                                setOpenDeployGateway(true);
                                setDeployGatewayData(data);
                              }}
                              proceduralStatus={
                                gateway?.procedural_status || ''
                              }
                              openConnectGatewayClusterTearSheet={(
                                gateway: Gateway
                              ) => {
                                setShowConnectClusterTearSheet(
                                  !showConnectClusterTearSheet
                                );
                                setSelectedGateway(gateway);
                              }}
                              gateway={gateway}
                            />
                          </Column>
                        ))
                      ) : (
                        <CardEmptyState
                          filterApplied={filterApplied}
                          emptyState={{
                            icon: images.noGatewayLargeIcon(),
                            header: t('emptyState.emptyContainerHeader'),
                            description: t(
                              'emptyState.emptyContainerDescription'
                            ),
                          }}
                        />
                      )
                    ) : (
                      <div className='no-resource-group'></div>
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      <DeployGateway
        open={showDeployGateway}
        gatewayNamesList={existingGatewayNames}
        onClose={(isRefreshRequired = false) => {
          setOpenDeployGateway(false);
          setDeployGatewayData(null);
          if (isRefreshRequired) refreshData();
        }}
        gatewayData={gatewayToBeDeployed}
      />
      {showConnectClusterTearSheet && (
        <ConnectGatewayCluster
          open={showConnectClusterTearSheet}
          onClose={() => {
            setShowConnectClusterTearSheet(false);
          }}
          onGatewayConnect={() => {
            refreshData();
          }}
          gateway={selectedGateway}
        />
      )}
    </div>
  );
};

export default GatewaysContainer;
