import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import images from '../../images/images';
import { useEffect, useState } from 'react';
import {
  Link,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  ApplicationData,
  Deployment,
  DeploymentEnvironment,
  DeploymentInstance,
  Error500Type,
  ServiceEndPoint,
} from '../../models/master';
import {
  getDeploymentEnv,
  getDeploymentEnvSubtypes,
  getPartitions,
} from '../../controllers/deploymentEnv';
import {
  Button,
  Column,
  InlineNotificationProps,
  Modal,
  SkeletonPlaceholder,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  deleteDeploymentServiceEndPoints,
  deleteDeploymentInstance,
  getAllDeploymentInstance,
  getApplication,
  getDeploymentServiceEndPoints,
  deleteDeployment,
  getDeployment,
} from '../../controllers/applicationApis';
import InstacneApplDepl from './InstanceApplicationDepl';
import ServicesEndPoints from './ServiceEndPointsApplDeployments';
import Error500 from '../Errors/Error500';

import './ApplicationDeploymentDetails.scss';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import IconWithToolTip from '../../components/IconWithToolTip/IconWithToolTip';
import { AxiosError } from 'axios';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';

interface FormattedDeploymentData {
  appName: string;
  envName: string;
  partitionName: string;
  envType: string;
  services: string;
  locationName: string;
}

type LocationState = {
  from: string;
};

const ApplicationDeploymentDetails = () => {
  const { t } = useTranslation('applicationDeploymentDetails');
  const [searchParams, setSearchParams] = useSearchParams();
  const deplId = searchParams.get('deplId');
  const appId = searchParams.get('appId');
  const [applicationDetails, setApplicationDetails] =
    useState<ApplicationData | null>(null);
  const [appDeploymentDetails, setAppDeploymentDetails] =
    useState<Deployment | null>(null);
  const [deploymentEnvironmentDetails, setDeploymentEnvironmentDetails] =
    useState<DeploymentEnvironment | null>(null);
  const [formattedAppDeploymentData, setFormattedAppDeploymentData] =
    useState<FormattedDeploymentData | null>(null);
  const [applDeplDetailsDataLoading, toogleApplDeplDetailsDataLoading] =
    useState(false);
  const [depEnvSubTypesNew, setDepEnvSubTypes] = useState<any>(null);
  const [instanceData, setInstanceData] = useState<DeploymentInstance[] | []>(
    []
  );
  const [partitionData, setPartitionData] = useState<
    DeploymentEnvironment[] | any
  >();
  const [serviceEndPointsData, setServiceEndPointsData] = useState<
    ServiceEndPoint[] | null
  >(null);
  const [deleteInstanceModalOpen, toggleDeleteInstanceModal] = useState(false);
  const [deleteServiceEndPointModal, toggleDeleteServiceEndPointModal] =
    useState(false);
  const [deleteDeploymentModalOpen, toggleDeploymentModal] = useState(false);
  const [deleteServiceEndPoint, setDeleteServiceEndPoint] =
    useState<ServiceEndPoint | null>(null);
  const [deleteInstance, setInstance] = useState<DeploymentInstance | null>(
    null
  );
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [notificationData, setNotificationData] =
    useState<InlineNotificationProps>({} as InlineNotificationProps);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [svcEndpointDeletionError, setSvcEndpointDeletionError] = useState({
    display: false,
    serviceId: '',
  });
  const [error500, setError500] = useState<null | Error500Type>(null);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState;

  const formatAppDeploymentData = (
    deploymentData: Deployment,
    deploymentEnvironmentData: DeploymentEnvironment,
    applicationData: ApplicationData
  ) => {
    const appDeplDetailsData: FormattedDeploymentData = {
      appName: '',
      envName: '',
      partitionName: '',
      envType: '',
      services: '',
      locationName: '',
    };

    const selectedDepEnvSubType = depEnvSubTypesNew?.find(
      (depEnv: { type_code: string | undefined }) =>
        depEnv.type_code === deploymentEnvironmentData.subtype
    );

    const environmentType = selectedDepEnvSubType
      ? selectedDepEnvSubType.type_name
      : '';

    let serviceValue = '';

    applicationData?.services.forEach((service, index) => {
      const seperator =
        index < applicationData?.services.length - 1 ? ', ' : '';
      serviceValue =
        serviceValue +
        service.name +
        ' (' +
        service.ports.map(port => port.port_number) +
        ')' +
        seperator;

      return null;
    });

    const selectedPartitionName = partitionData?.find(
      (partitionName: { resource_id: string | undefined }) =>
        partitionName.resource_id === deploymentData?.partition_id
    );
    const partitionName = selectedPartitionName
      ? selectedPartitionName.name
      : '';

    appDeplDetailsData.appName = applicationData?.name;
    appDeplDetailsData.envName = deploymentEnvironmentData?.name ?? '';
    appDeplDetailsData.partitionName = partitionName;
    appDeplDetailsData.envType = environmentType;
    appDeplDetailsData.locationName =
      deploymentEnvironmentDetails?.location_name;
    setFormattedAppDeploymentData(appDeplDetailsData);
  };

  const fetchApplication = async () => {
    try {
      let res = await getApplication(appId);
      setApplicationDetails(res);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 404) {
        navigate('/404');
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }

      toogleApplDeplDetailsDataLoading(false);
    }
  };

  const fetchAppDeployment = async () => {
    try {
      const deploymentData = await getDeployment(appId, deplId);
      setAppDeploymentDetails(deploymentData);
      fetchDeploymentEnv(deploymentData?.depl_env_id);
      fetchPartitionData(deploymentData?.depl_env_id);
      fetchDeploymentEnvSubTypes();
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 404) {
        navigate('/404');
      }
      toogleApplDeplDetailsDataLoading(false);
    }
  };

  const fetchDeploymentEnv = async (envId: string) => {
    try {
      const res = await getDeploymentEnv(envId);
      setDeploymentEnvironmentDetails(res);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDeploymentEnvSubTypes = async () => {
    const depEnvSubTypes = await getDeploymentEnvSubtypes();
    setDepEnvSubTypes(depEnvSubTypes);
  };

  const fetchPartitionData = async (envId: string) => {
    try {
      const resPartition = await getPartitions(envId);
      setPartitionData(resPartition.partitions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchServiceEndPointsData = async () => {
    try {
      const resServiceEndpoint = await getDeploymentServiceEndPoints(
        appId,
        deplId
      );
      setServiceEndPointsData(resServiceEndpoint.svc_endpoints);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInstanceData = async () => {
    let resInstance = await getAllDeploymentInstance(appId, deplId);
    setInstanceData(resInstance.insts);
  };

  const confirmDeleteServiceEndPoint = async (serviceEndpointId: string) => {
    toggleDeleteServiceEndPointModal(false);
    setSvcEndpointDeletionError({
      display: false,
      serviceId: '',
    });
    try {
      setDisableDeleteBtn(true);
      toogleApplDeplDetailsDataLoading(true);
      await deleteDeploymentServiceEndPoints(
        appId,
        deplId,
        deleteServiceEndPoint?.resource_id
      );
      setDeleteServiceEndPoint(null);
      setServiceEndPointsData(
        serviceEndPointsData!.filter(
          serviceEndpoint => serviceEndpoint?.resource_id !== serviceEndpointId
        )
      );
    } catch (error) {
      const err = error as AxiosError;
      console.error(error);
      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? `${t('authErrorTitle')}`
            : `${t('serviceEndPoints.errorNotificationTitle')}`,
        subtitle:
          err.response?.status === 403
            ? `${t('authErrorSubtitle')}`
            : t('serviceEndPoints.removeServiceEndPointFail'),
      });

      setSvcEndpointDeletionError({
        display: true,
        serviceId: deleteServiceEndPoint?.service_id ?? '',
      });
    } finally {
      toogleApplDeplDetailsDataLoading(false);
      setDisableDeleteBtn(false);
    }
  };

  const requestDeleteInstance = (instanceId: any) => {
    toggleDeleteInstanceModal(true);
    setInstance(instanceId);
  };

  const requestDeleteServiceEndPoint = (serviceEndPoint: ServiceEndPoint) => {
    setDeleteServiceEndPoint(serviceEndPoint);
    toggleDeleteServiceEndPointModal(true);
  };

  const confirmDeleteInstance = async (instanceDeleteId: any) => {
    toggleDeleteInstanceModal(false);
    try {
      setDisableDeleteBtn(true);
      toogleApplDeplDetailsDataLoading(true);
      await deleteDeploymentInstance(
        appId,
        deplId,
        deleteInstance?.resource_id
      );
      setInstance(null);
      setInstanceData(
        instanceData!.filter(
          instance => instance?.resource_id !== instanceDeleteId
        )
      );
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('instances.successNotificationTitle'),
        subtitle: t('instances.removeServiceSuccess'),
      });
    } catch (error) {
      const err = error as AxiosError;
      console.error(error);
      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? `${t('authErrorTitle')}`
            : `${t('instances.errorNotificationTitle')}`,
        subtitle:
          err.response?.status === 403
            ? `${t('authErrorSubtitle')}`
            : t('instances.removeInstanceFail'),
      });

      setOpenErrorModal(true);
    } finally {
      toogleApplDeplDetailsDataLoading(false);
      setDisableDeleteBtn(false);
    }
  };

  const handleDeleteDeployment = async (deploymentDeleteId: any) => {
    toggleDeploymentModal(false);
    try {
      toogleApplDeplDetailsDataLoading(true);
      let successMsg = t('deployment.removeDeployementSuccess');
      let successUrl = `/applicationDetails?appId=${appId}`;
      if (state.from === 'namespaceDetailsPage') {
        successMsg = t('deployment.removeDeployementSuccessNamespace');
        successUrl = `/partitionDetails?depEnvId=${appDeploymentDetails?.depl_env_id}&partitionId=${appDeploymentDetails?.partition_id}`;
      }
      await deleteDeployment(appId, deploymentDeleteId);
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('deployment.successNotificationTitle'),
        subtitle: successMsg,
      });
      navigate(successUrl);
    } catch (error) {
      const err = error as AxiosError;

      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? `${t('authErrorTitle')}`
            : `${t('deploymentNotificationMessages.errorNotificationTitle')}`,
        subtitle:
          err.response?.status === 403
            ? `${t('authErrorSubtitle')}`
            : t('deploymentNotificationMessages.removeAppDeploymentFail'),
      });

      console.error(error);
    } finally {
      toogleApplDeplDetailsDataLoading(false);
    }
  };

  const requestDeleteDeployment = () => {
    toggleDeploymentModal(true);
  };

  const getModalParams = (type: string) => {
    if (type === 'instanceModal') {
      return {
        header: t('instances.deleteModal.header'),
        body: t('instances.deleteModal.body', {
          local_ip: deleteInstance?.ip_address,
        }),
        confirmFn: () => confirmDeleteInstance(deleteInstance?.resource_id),
        cancelFn: () => toggleDeleteInstanceModal(false),
      };
    } else if (type === 'serviceEndPointsModal') {
      return {
        header: t('serviceEndPoints.deleteModal.header'),
        body: t('serviceEndPoints.deleteModal.body', {
          address: `${deleteServiceEndPoint?.hostname_targets}${
            deleteServiceEndPoint?.hostname_targets !== '' ? ',' : ''
          }${deleteServiceEndPoint?.ip_targets}`,
        }),
        confirmFn: () =>
          confirmDeleteServiceEndPoint(
            deleteServiceEndPoint?.resource_id ?? ''
          ),
        cancelFn: () => toggleDeleteServiceEndPointModal(false),
      };
    } else if (type === 'deploymentModal') {
      return {
        header: t('deployment.deleteModal.header'),
        body: t('deployment.deleteModal.body', {
          application_name: formattedAppDeploymentData?.appName,
          env_name: formattedAppDeploymentData?.envName,
          partitionName: formattedAppDeploymentData?.partitionName,
        }),
        confirmFn: () =>
          handleDeleteDeployment(appDeploymentDetails?.resource_id),
        cancelFn: () => toggleDeploymentModal(false),
      };
    }
  };

  const deleteModal = (type: string, isOpen: boolean) => {
    const modalParams = getModalParams(type);
    return (
      <Modal
        className='delete-application-deployment-modal'
        danger
        modalHeading={modalParams?.header ?? ''}
        onRequestClose={() => modalParams?.cancelFn()}
        onRequestSubmit={() => modalParams?.confirmFn()}
        primaryButtonText={t('instances.deleteModal.confirm')}
        secondaryButtonText={t('instances.deleteModal.cancel')}
        open={isOpen}
        primaryButtonDisabled={disableDeleteBtn}
        size='sm'
      >
        {modalParams?.body ?? ''}
      </Modal>
    );
  };

  const getTitle = () => {
    return (
      <div className='header__title_appdepl'>
        <GenericTruncateString
          str={formattedAppDeploymentData?.envName ?? ''}
        />{' '}
        /
        <GenericTruncateString
          str={formattedAppDeploymentData?.partitionName ?? ''}
        />
      </div>
    );
  };

  const getBreadCrumbs = () => {
    if (state != null && state.from === 'namespaceDetailsPage')
      return [
        {
          url: '/',
          label: t('home'),
        },
        {
          url: '/deploymentenvironments',
          label: t('deploymentEnvironments'),
        },
        {
          url: `/deploymentEnvironmentDetails?deplId=${appDeploymentDetails?.depl_env_id}`,
          label: formattedAppDeploymentData?.envName ?? '',
        },
        {
          url: `/partitionDetails?depEnvId=${appDeploymentDetails?.depl_env_id}&partitionId=${appDeploymentDetails?.partition_id}`,
          label: formattedAppDeploymentData?.partitionName ?? '',
        },
      ];
    return [
      {
        url: '/',
        label: t('home'),
      },
      {
        url: '/applications',
        label: t('applications'),
      },
      {
        url: `/applicationDetails?appId=${appId}`,
        label: formattedAppDeploymentData?.appName ?? '',
      },
    ];
  };

  const handleRegisterInstance = (instance: DeploymentInstance) => {
    Array.isArray(instanceData)
      ? setInstanceData(prevData => [instance, ...prevData])
      : setInstanceData([instance]);

    // Temporary fix for consumer_ip not assigned for few seconds when we add a new instance
    setTimeout(() => {
      fetchInstanceData();
    }, 5000);
  };

  const handleRegisterServiceEndpoint = (serviceEndPoint: ServiceEndPoint) => {
    Array.isArray(serviceEndPointsData)
      ? setServiceEndPointsData(prevData => [
          serviceEndPoint,
          ...(prevData ? prevData : []),
        ])
      : setServiceEndPointsData([serviceEndPoint]);
  };

  useEffect(() => {
    toogleApplDeplDetailsDataLoading(true);
    fetchApplication();
    fetchAppDeployment();
    fetchServiceEndPointsData();
    fetchInstanceData();
  }, []);

  useEffect(() => {
    if (
      appDeploymentDetails !== null &&
      deploymentEnvironmentDetails !== null &&
      applicationDetails !== null
    ) {
      formatAppDeploymentData(
        appDeploymentDetails,
        deploymentEnvironmentDetails,
        applicationDetails
      );
    }
  }, [appDeploymentDetails, deploymentEnvironmentDetails, applicationDetails]);

  useEffect(() => {
    if (formattedAppDeploymentData !== null) {
      toogleApplDeplDetailsDataLoading(false);
    }
  }, [formattedAppDeploymentData]);

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='application-depl-details'>
      <Header
        loading={applDeplDetailsDataLoading}
        title={getTitle()}
        subTitle={formattedAppDeploymentData?.appName ?? ''}
        headerIcon={
          applicationDetails?.is_discovered ? (
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          ) : (
            false
          )
        }
        breadcrumbs={getBreadCrumbs()}
      />
      <div className='appl-depl-details-card'>
        <Column lg={16} className='appl-depl-card'>
          {applDeplDetailsDataLoading ? (
            <div className='page-content'>
              <SkeletonPlaceholder className='details-tile-skeleton' />
            </div>
          ) : (
            <DetailsCard
              type={'APPLICATION_DEPLYOMENT'}
              isEditable={false}
              data={[
                {
                  key: 'applicationName',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      <Link
                        className='no-underline-link'
                        to={`/applicationDetails?appId=${appId}`}
                      >
                        <GenericTruncateString
                          str={formattedAppDeploymentData?.appName ?? ''}
                          tableView={false}
                          limit={16}
                        />
                      </Link>
                    </div>
                  ),
                },
                {
                  key: 'environment / partition',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      <Link
                        className='no-underline-link'
                        to={`/deploymentEnvironmentDetails?deplId=${appDeploymentDetails?.depl_env_id}`}
                        data-testId='app-depl-details-environment'
                      >
                        <GenericTruncateString
                          str={formattedAppDeploymentData?.envName ?? ''}
                          tableView={false}
                          limit={14}
                        />
                      </Link>
                      <span> / </span>
                      <div>
                        <Link
                          className='no-underline-link'
                          to={`/partitionDetails?depEnvId=${appDeploymentDetails?.depl_env_id}&partitionId=${appDeploymentDetails?.partition_id}`}
                          data-testId='app-depl-details-partitions'
                        >
                          <GenericTruncateString
                            str={
                              formattedAppDeploymentData?.partitionName ?? ''
                            }
                            tableView={false}
                            limit={14}
                          />
                        </Link>
                      </div>
                    </div>
                  ),
                },
                {
                  key: 'environmentType',
                  dataTestId: 'app-depl-details',
                  value: formattedAppDeploymentData?.envType,
                },
                {
                  key: 'services',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      {formattedAppDeploymentData?.services
                        ? formattedAppDeploymentData?.services
                        : '—'}
                    </div>
                  ),
                },
                {
                  key: 'location',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      <Link
                        className='no-underline-link'
                        to={`/locationDetails?cloudId=${deploymentEnvironmentDetails?.cloud_id}&locationId=${deploymentEnvironmentDetails?.location_id}`}
                      >
                        <GenericTruncateString
                          str={formattedAppDeploymentData?.locationName ?? ''}
                          tableView={false}
                          limit={14}
                        />
                      </Link>
                    </div>
                  ),
                },
              ]}
              detailsCardName={t('header')}
              data-testid='app-depl-details-heading'
            />
          )}
        </Column>
      </div>
      <div className='instances-table-card'>
        <ServicesEndPoints
          deploymentType={appDeploymentDetails?.type ?? ''}
          svcEndpointDeletionError={svcEndpointDeletionError}
          closeDeleteErrorNotification={() =>
            setSvcEndpointDeletionError({
              display: false,
              serviceId: '',
            })
          }
          serviceEndPointsData={serviceEndPointsData}
          onRefresh={() => console.log()}
          removeServiceEndPoint={(serviceEndPoint: ServiceEndPoint) =>
            requestDeleteServiceEndPoint(serviceEndPoint)
          }
          filteredDataCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          filtersAppliedCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          onPageChange={function (pageData: any): void {
            throw new Error('Function not implemented.');
          }}
          sortRows={function (arg0: unknown, sortDirection: string): void {
            throw new Error('Function not implemented.');
          }}
          applicationId={appId}
          deploymentId={deplId}
          deploymentEnvId={appDeploymentDetails?.depl_env_id ?? ''}
          services={applicationDetails?.services ?? null}
          onRegisterServiceEndpoint={handleRegisterServiceEndpoint}
          svcEndpointDataLoading={applDeplDetailsDataLoading}
        />
      </div>
      <div className='instances-table-card'>
        <InstacneApplDepl
          readOnly={appDeploymentDetails?.type === 'cluster' ? true : false}
          instanceData={instanceData}
          onRefresh={() => console.log()}
          removeInstance={instanceId => requestDeleteInstance(instanceId)}
          filteredDataCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          filtersAppliedCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          onPageChange={function (pageData: any): void {
            throw new Error('Function not implemented.');
          }}
          sortRows={function (arg0: unknown, sortDirection: string): void {
            throw new Error('Function not implemented.');
          }}
          applicationId={appId}
          deploymentId={deplId}
          onRegisterInstance={handleRegisterInstance}
          resourceGroupId={
            deploymentEnvironmentDetails?.resource_group_id ?? null
          }
          instanceDataLoading={applDeplDetailsDataLoading}
        />
      </div>
      {instanceData.length > 0 ||
      (serviceEndPointsData && serviceEndPointsData.length > 0) ? (
        <TooltipDefinition
          tooltipText={t('disableTooltipText') as string}
          direction='top'
        >
          <Button
            className='deploymentDeteteButton'
            kind='danger--ghost'
            disabled={true}
          >
            {t('deleteApplicationDeployment')}
          </Button>
        </TooltipDefinition>
      ) : (
        <Button
          className='deploymentDeteteButton'
          kind='danger--ghost'
          disabled={false}
          onClick={() => requestDeleteDeployment()}
        >
          {t('deleteApplicationDeployment')}
        </Button>
      )}
      {deleteModal('instanceModal', deleteInstanceModalOpen)}
      {deleteModal('serviceEndPointsModal', deleteServiceEndPointModal)}
      {deleteModal('deploymentModal', deleteDeploymentModalOpen)}
    </div>
  );
};

export default ApplicationDeploymentDetails;
