import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Button,
  TableToolbarContent,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTableSkeleton,
  TableProps,
  Pagination,
  TooltipDefinition,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import { DeploymentData, DeploymentInstanceData } from '../../models/master';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from '../../models/dataTable';
import images from '../../images/images';
import { VerticalEmptyState } from '../EmptyState/EmptyState';
import './DeploymentsTable.scss';
import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error403Card from '../ErrorState/Error403Card';

interface PropsInterface {
  applicationId?: string;
  rows: DeploymentData[];
  headers: DataTableHeader[];
  instanceMap: Map<string, DeploymentInstanceData[]>;
  loading: boolean;
  style?: any;
  id: any;
  fetchDeploymentsData?: (id: any, flag: string) => void;
  openAddAppDeployment?: () => void;
  onSearchCallBack: (searchVal: string) => void;
  sortRows(arg0: unknown, direction: string): void;
  error403Flag?: boolean;
}

const DeploymentsTable = (Props: PropsInterface) => {
  const {
    applicationId,
    rows,
    headers,
    instanceMap,
    loading,
    style,
    openAddAppDeployment,
    onSearchCallBack,
    sortRows,
    error403Flag,
  } = Props;
  const { t } = useTranslation('applicationDetails');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const { trackButtonClicked } = useAnalytics();

  const getFilteredRows = () => {
    if (!searchText) {
      return [...rows];
    }
    return rows?.filter(deplEnv =>
      deplEnv?.environment.name
        ?.toLowerCase()
        .includes(searchText?.toLowerCase())
    );
  };

  const handlePagination = (change: any) => {
    setPage(change.page);
    setPageSize(change.pageSize);
  };

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (e && e.target) onSearchCallBack(e?.target?.value ?? '');
  };

  /**
   * @description - Sorting function which is called on clicking sortable table headers
   * @param index {number} - Index of the header which is clicked.
   */
  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    setSortDirection(newSortDirection);
    const sortedKey =
      headers[index]?.originalKey !== undefined
        ? headers[index]?.originalKey ?? ''
        : '';
    setSortKey(sortedKey);
    sortRows(
      {
        id: headers[index].originalKey,
        text: headers[index].header,
      },
      newSortDirection
    );
  };

  const emptyState = () => {
    if (rows.length === 0 && !loading) {
      if (searchText.length) {
        return (
          <VerticalEmptyState
            icon={images.NotFoundLarge()}
            header={t('notFound.header')}
            description={t('notFound.description')}
            buttonText={t('notFound.text') ?? ''}
            link={false}
            additionalClassName='small'
            click={() => {}}
          />
        );
      } else {
        return (
          <VerticalEmptyState
            icon={images.NoApplicationDeploymentSmall()}
            header={t('noDeployment')}
            description={t('noDeploymentDes')}
            buttonText={t('deployments.register') ?? ''}
            link={true}
            additionalClassName='small'
            click={openAddAppDeployment}
          />
        );
      }
    } else {
      return null;
    }
  };

  const render403Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error403Card />
        </div>
      </div>
    );
  };

  if (loading && !error403Flag) {
    return (
      <div className='deployments-table-container'>
        <DataTableSkeleton
          showHeader
          showToolbar
          rowCount={3}
          columnCount={7}
        />
      </div>
    );
  }
  return (
    <div className='deployments-table-container'>
      <div
        className={'deployments-header' + (rows.length > 0 ? '' : ' border')}
        style={style ? style : {}}
        data-testid='deployment-header'
      >
        {rows.length > 0
          ? t('deployments.header.1', { count: rows.length })
          : t('deployments.header.0')}
      </div>

      <DataTable rows={getFilteredRows()} headers={headers} isSortable>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }: {
          rows: DataTableRow[];
          headers: DataTableHeader[];
          getTableProps: () => TableProps;
          getHeaderProps: any;
          getRowProps: any;
          getToolbarProps: any;
          getTableContainerProps: any;
        }) => (
          <TableContainer {...getTableContainerProps()}>
            {!error403Flag && (
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    expanded
                    onChange={e => applySearch(e)}
                    placeholder={t('deployments.toolbarSearchPlaceholder')}
                  />
                  {(rows.length !== 0 || searchText.length > 0) && (
                    <Button
                      kind='ghost'
                      className='add-deployment-button'
                      renderIcon={Add16}
                      onClick={openAddAppDeployment}
                    >
                      <span className='text'>{t('deployments.register')}</span>
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>
            )}
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header: DataTableHeader, index: number) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({
                        header,
                        isSortable: Boolean(header.sort),
                      })}
                      onClick={() =>
                        header.sort ? handleChangeSort(index) : undefined
                      }
                      isSortHeader={Boolean(header.sort)}
                      sortDirection={
                        headers[index].originalKey === sortKey
                          ? sortDirection
                          : 'NONE'
                      }
                      data-testid={`app-depl-table-head-${header?.key}`}
                      style={header?.style ? { ...header.style } : {}}
                    >
                      {header.key === 'type' ? (
                        <TooltipDefinition
                          className='header-tooltip'
                          direction='top'
                          tooltipText={t(
                            'deployments.tableHeaders.typeTooltip'
                          )}
                        >
                          {header.header}
                        </TooltipDefinition>
                      ) : (
                        header.header
                      )}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              {rows.length > 0 && (
                <TableBody>
                  {rows.length > 0 &&
                    rows.map((row: DataTableRow, index: number) => (
                      <TableRow {...getRowProps({ row })} key={row.id}>
                        {row.cells.map((cell: DataTableCell) => {
                          if (cell.info.header === 'environment') {
                            return (
                              <TableCell
                                key={row.id + cell.id}
                                data-testId={'app-depl-table-value-environment'}
                              >
                                <Link
                                  className='depl-env-link'
                                  to={`/applicationDeplDetails?appId=${applicationId}&deplId=${row.id}`}
                                  state={{ from: 'applicationDetailsPAge' }}
                                  onClick={() =>
                                    trackButtonClicked(
                                      analyticsData['Application Details']
                                        .events.deploymentInstanceLink.props,
                                      analyticsData['Application Details']
                                        .events.deploymentInstanceLink.event
                                    )
                                  }
                                >
                                  <GenericTruncateString
                                    str={cell.value.name}
                                    tableView={true}
                                  />
                                </Link>
                              </TableCell>
                            );
                          } else if (cell.info.header === 'location') {
                            return (
                              <TableCell
                                key={row.id + cell.id}
                                data-testId={'app-depl-table-value-location'}
                              >
                                <Link
                                  className='depl-env-link'
                                  to={`/locationDetails?locationId=${cell.value}`}
                                  state={{ from: 'applicationDetailsPage ' }}
                                  onClick={() =>
                                    trackButtonClicked(
                                      analyticsData['Application Details']
                                        .events.deploymentInstanceLink.props,
                                      analyticsData['Application Details']
                                        .events.deploymentInstanceLink.event
                                    )
                                  }
                                >
                                  <GenericTruncateString
                                    str={cell.value}
                                    tableView={true}
                                  />
                                </Link>
                              </TableCell>
                            );
                          } else if (
                            cell.info.header === 'addServiceEndpoint'
                          ) {
                            if (cell.value === true) {
                              return (
                                <TableCell
                                  key={row.id + cell.id}
                                  data-testId={'app-depl-table-add-endpoint'}
                                >
                                  <Link
                                    className='register-endpoint-link'
                                    to={`/applicationDeplDetails?appId=${applicationId}&deplId=${row.id}`}
                                  >
                                    <TooltipDefinition
                                      tooltipText={t(
                                        'deployments.tableHeaders.registerSvcEndpointTooltip'
                                      )}
                                      direction='bottom'
                                    >
                                      {t('deployments.registerEndpoints')}
                                    </TooltipDefinition>
                                  </Link>
                                </TableCell>
                              );
                            } else {
                              return <TableCell key={cell.info.header} />;
                            }
                          } else {
                            return (
                              <TableCell
                                key={row.id + cell.id}
                                data-testId={`app-depl-table-value-${cell.info.header}`}
                              >
                                {cell.value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            {rows.length > 0 && (
              <Pagination
                totalItems={rows.length}
                itemsPerPageText={t('deployments.pagination.itemsPerPage')}
                itemRangeText={(min, max, totalLength) => {
                  return t('deployments.pagination.itemKey', {
                    min: min,
                    max: max,
                    total: totalLength,
                  });
                }}
                pageSize={pageSize}
                page={page}
                pageRangeText={(current, total) =>
                  t('deployments.pagination.pageKey', { sum: total })
                }
                pageSizes={[10, 25, 50, 100, 250]}
                onChange={change => handlePagination(change)}
              />
            )}
          </TableContainer>
        )}
      </DataTable>
      {error403Flag ? render403Container() : null}
      {!error403Flag && Props.rows.length === 0 ? emptyState() : null}
    </div>
  );
};

export default DeploymentsTable;
