import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ContentSwitcher,
  Button,
  Row,
  Column,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { List32, Grid32 } from '@carbon/icons-react';

import { useNavigate } from 'react-router-dom';
import dateUtils from '../../lib/dates';

import ApplicationsTable from './ApplicationsTable/ApplicationsTable';
import ResourceCard from '../../components/ApplicationCard/ResourceCard';
import Header from '../../components/Header/Header';
import AddApplication from './AddApplication/AddApplication';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import sortData from '../../lib/tableSort';
import {
  getCombinedLabelsForFilter,
  manageFiltersFromStorage,
} from '../../lib/utils';
import {
  ApplicationData,
  AppliedFilter,
  Error500Type,
  NetworkSegment,
  ResourceGroup,
} from '../../models/master';

import { getApplications } from '../../controllers/applicationApis';
import { FlexGrid } from '@carbon/react';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';

import './ApplicationsContainer.scss';
import images from '../../images/images';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import { getDeploymentEnvs } from '../../controllers/deploymentEnv';
import { AxiosError } from 'axios';
import Error403Card from '../../components/ErrorState/Error403Card';
import useAnalytics from '../../lib/useAnalytics';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';
import analyticsData from '../../lib/analyticsEventData';
import { filterTypes } from '../../lib/enums';
import Error500Card from '../../components/ErrorState/Error500Card';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';

type LocationState = {
  resourceType: string;
  navigateBack: boolean;
};

type View = 'table' | 'card';

const defaultPermissionMap = {
  applications: true,
  service: true,
  resourceGroup: true,
  deploymentEnv: true,
  networkSegments: true,
};

const ApplicationsContainer = () => {
  const { t } = useTranslation('applicationsContainer');

  const { pageViewed, trackButtonClicked } = useAnalytics();

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [applicationsData, setApplicationsData] = useState<
    ApplicationData[] | null
  >(null);
  const [customApplicationsData, setCustomApplicationsData] = useState<
    ApplicationData[] | null
  >(null);
  const [showAddApplication, setShowAddApplication] = useState(false);
  const [appDataLoading, toggleAppDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<ApplicationData[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [applicationGroupsData, setApplicationGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [networkSegmentsData, setNetworkSegmentsData] = useState<
    NetworkSegment[] | null
  >(null);

  const [allDepEnvs, setAllDepEnvs] = useState<any>();
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;

  const refreshData = async () => {
    setApplicationsData(null);
    setSortKey('');
    setSortDirection('NONE');
    getApplications(true, false)
      .then(response => {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          applications: true,
        }));

        if (error500) {
          setError500(null);
        }
        setCustomApplicationsData(response as ApplicationData[]);
      })
      .catch(error => {
        setApplicationsData([]);
        toggleAppDataLoading(false);
        if (error.response?.status === 403) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            applications: false,
          }));
        }

        if (error.response?.status >= 500) {
          setError500(error.response?.status?.toString());
        }

        console.error(error);
      });

    getResourceGroups(true)
      .then(response => {
        setApplicationGroupsData(response.resource_groups as ResourceGroup[]);
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          resourceGroup: true,
        }));
      })
      .catch(error => {
        if (error.response?.status === 403) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            resourceGroup: false,
          }));
        }
        console.error(error);
      });

    try {
      const networkSegmentResponse = await getNetworkSegments();
      setNetworkSegmentsData(networkSegmentResponse as NetworkSegment[]);
      if (!permissionMap.networkSegments) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegments: true,
        }));
      }
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegments: false,
        }));
      }
    }

    setFilterApplied(manageFiltersFromStorage());
  };

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(applicationGroupsData) &&
      applicationGroupsData.find(
        resouceGroup => resouceGroup.resource_id === id
      );
    return resouceGroup ? resouceGroup.name : '—';
  };

  const getNetworkSegmentName = (id: string) => {
    const networkSegments =
      id === DEFAULT_NETWORK_SEGMENT_ID
        ? defaultNetworkSegmentObject
        : id &&
          Array.isArray(networkSegmentsData) &&
          networkSegmentsData.find(segments => segments.resource_id === id);
    return networkSegments ? networkSegments.name : '—';
  };

  const customCreatedApplicationData = () => {
    let applicationContainerData = customApplicationsData?.map(
      applicationData => ({
        ...applicationData,
        resource_group_name: getResourceGroupName(
          applicationData.resource_group_id
        ),
        network_segment_name: getNetworkSegmentName(
          applicationData.network_segment_id
        ),
      })
    );
    setApplicationsData(applicationContainerData as ApplicationData[]);
  };

  const removeDuplicateNetworkSegments = () => {
    let networkSegmentElements = new Set(
      applicationsData?.map((item: any) => item.network_segment_name)
    );
    let segmentElements = Array.from(networkSegmentElements);
    let elementIndex = segmentElements.indexOf('—');
    if (elementIndex > -1) {
      segmentElements.splice(elementIndex, 1);
    }
    return segmentElements;
  };

  const leftInlineMultiSelectFilter: any = [
    {
      key: 'applicationNetworkSegment',
      type: 'multi',
      values: removeDuplicateNetworkSegments().map(item => {
        return {
          label: item,
          value: item,
        };
      }) as any,
      placeHolderText: removeDuplicateNetworkSegments().length,
    },
  ];

  const createdApp = () => {
    setShowAddApplication(false);
    refreshData();
    if (state) {
      state.navigateBack = false;
    }
  };

  const closeAddApplicationTearSheet = () => {
    setShowAddApplication(false);
    if (state?.navigateBack) {
      navigate(-1);
    }
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (Array.isArray(applicationsData)) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedApplication = Array.isArray(applicationsData)
          ? applicationsData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setApplicationsData([...sortedApplication]);
      } else {
        setSortDirection('DESC');
        const sortedApplication = Array.isArray(applicationsData)
          ? applicationsData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setApplicationsData([...sortedApplication]);
      }
    }
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={applicationsData ? applicationsData : []}
        filteredDataCallback={data =>
          setFilteredData(data as ApplicationData[] | [])
        }
        persistFilter
        filteredData={
          applicationsData
            ? filterApplied.length > 0
              ? filteredData
              : applicationsData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={[
          {
            key: 'applicationGroup',
            type: 'single',
            label: t('filters.applicationGroup'),
            placeHolderText: t('filters.applicationGroupPlaceholder'),
            values: [
              ...Array.from(
                new Set(
                  (applicationGroupsData
                    ? applicationGroupsData.filter(
                        resource => resource.type === 'application'
                      )
                    : []
                  ).map(appGroup => appGroup.name)
                )
              ),
            ],
          },
          {
            key: filterTypes.ALLLABELS,
            type: 'multi',
            label: t('filters.labels'),
            placeHolderText: t('filters.labelsPlaceholder'),
            values: [
              ...Array.from(
                new Set(
                  (applicationsData ? applicationsData : [])
                    .map(application =>
                      getCombinedLabelsForFilter(
                        application?.labels,
                        application?.discovered_labels
                      )
                    )
                    .flat()
                )
              ),
            ],
          },
        ]}
        onRefresh={() => refreshData()}
        leftInlineMultiSelectFilter={leftInlineMultiSelectFilter}
      />
    );
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  useEffect(() => {
    if (state?.resourceType === 'APPLICATION') {
      setShowAddApplication(true);
    }
  }, [state]);

  useEffect(() => {
    pageViewed('Applications');
    toggleAppDataLoading(true);
    refreshData();
  }, []);

  useEffect(() => {
    customCreatedApplicationData();
  }, [customApplicationsData]);

  useEffect(() => {
    fetchDeploymentData();
  }, []);

  //fetching all Deployment Environments data for dislaying name in the Tooltip in the card view
  async function fetchDeploymentData() {
    try {
      const response = await getDeploymentEnvs();
      setAllDepEnvs(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    Array.isArray(applicationsData)
      ? toggleAppDataLoading(false)
      : toggleAppDataLoading(true);
  }, [applicationsData]);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const learnMore = {
    url:
      navigator.language === 'fr' ||
      navigator.language === 'fr-FR' ||
      navigator.language === 'fr-BE' ||
      navigator.language === 'fr-CA' ||
      navigator.language === 'fr-CH'
        ? 'https://ibm.biz/mesh-apps-fr'
        : 'https://ibm.biz/mesh-apps',
    label: t('learnMore'),
  };

  return (
    <div className='applications-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        learnMoreLink={learnMore}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => {
              trackButtonClicked(
                analyticsData['Applications'].events.registerAppBtn.props,
                analyticsData['Applications'].events.registerAppBtn.event
              );
              setShowAddApplication(true);
            },
            text: t('addApplication'),
            disabled: appDataLoading,
          },
        ]}
      />
      <div className='page-content'>
        <div className='applications-switcher'>
          {view !== 'table' ? (
            <>
              {appDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='application-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <ApplicationsTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              onPageChange={(pageData: any) => setPageChange(pageData)}
              rows={
                Array.isArray(applicationsData)
                  ? filterApplied.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(applicationsData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                applicationsData
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : applicationsData.length
                  : 0
              }
              filteredDataSet={
                applicationsData
                  ? filterApplied.length > 0
                    ? filteredData
                    : applicationsData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as ApplicationData[] | []);
                setPageNumber(1);
              }}
              data={applicationsData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => {
                setFilterApplied(data);
              }}
              persistFilter
              filters={[
                {
                  key: 'applicationGroup',
                  type: 'single',
                  label: t('filters.applicationGroup'),
                  placeHolderText: t('filters.applicationGroupPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        (applicationGroupsData
                          ? applicationGroupsData.filter(
                              resource => resource.type === 'application'
                            )
                          : []
                        ).map(appGroup => appGroup.name)
                      )
                    ),
                  ],
                },
                {
                  key: filterTypes.ALLLABELS,
                  type: 'multi',
                  label: t('filters.labels'),
                  placeHolderText: t('filters.labelsPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        (applicationsData ? applicationsData : [])
                          .map(application =>
                            getCombinedLabelsForFilter(
                              application?.labels,
                              application?.discovered_labels
                            )
                          )
                          .flat()
                      )
                    ),
                  ],
                },
              ]}
              onRefresh={() => refreshData()}
              applicationGroupsList={applicationGroupsData}
              networkSegmentList={networkSegmentsData}
              error403Flag={!permissionMap['applications']}
              error500Flag={!!error500}
              resourceGroupNotAuthorized={!permissionMap['resourceGroup']}
              networkSegmentNotAuthorized={!permissionMap['networkSegments']}
              leftInlineMultiSelectFilter={leftInlineMultiSelectFilter}
            />
          ) : !permissionMap['applications'] ? (
            <Error403Card />
          ) : error500 ? (
            <Error500Card />
          ) : appDataLoading ? (
            <div>
              {!applicationsData && renderFilter()}
              <div className='skeleton-card-view'>
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
              </div>
            </div>
          ) : (
            <div>
              {renderFilter()}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {applicationsData &&
                    (filterApplied.length > 0 ? filteredData : applicationsData)
                      .length > 0 ? (
                      (filterApplied.length > 0
                        ? filteredData
                        : applicationsData
                      ).map(application => (
                        <Column lg={4} md={4} className='resource-card'>
                          <ResourceCard
                            key={application.resource_id}
                            resourceType={'APPLICATION'}
                            header={application.name}
                            tags={application.labels}
                            data={{
                              deployments: application.deployments,
                              allDepEnvs: allDepEnvs,
                              deploymentsNotAuthorized:
                                !application.deploymentPermission,
                              applicationGroup: !permissionMap.resourceGroup ? (
                                <GenericStatusField status={'notAuthorised'} />
                              ) : (
                                application.resource_group_name
                              ),
                              networkSegmentName:
                                !permissionMap.networkSegments ? (
                                  <GenericStatusField
                                    status={'notAuthorised'}
                                  />
                                ) : (
                                  application.network_segment_name
                                ),
                            }}
                            updated={dateUtils.getUserFriendlyDate(
                              application.updated_at
                            )}
                            path={`/applicationDetails?appId=${application.resource_id}`}
                          />
                        </Column>
                      ))
                    ) : (
                      <CardEmptyState
                        filterApplied={filterApplied}
                        emptyState={{
                          icon: images.noApplicationsIcon(),
                          header: t('emptyState.emptyContainerHeader'),
                          description: t(
                            'emptyState.emptyContainerDescription'
                          ),
                        }}
                      />
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>

      {showAddApplication && (
        <AddApplication
          applicationList={applicationsData}
          open={showAddApplication}
          onClose={() => closeAddApplicationTearSheet()}
          createdApp={() => createdApp()}
          mode={'ADD'}
        />
      )}
    </div>
  );
};

export default ApplicationsContainer;
