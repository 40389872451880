import React, { useState } from 'react';
import { TrashCan16 } from '@carbon/icons-react';
import { Column, Row, Link as CarbonLink } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import images from '../../images/images';
import dateUtils from '../../lib/dates';
import { DeploymentInstance } from '../../models/master';
import InlineNotification from '../../components/Notifications/Inline/Notification';

import './InstanceApplicationDepl.scss';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

const InstanceApplicationDepl = (props: {
  readOnly: boolean;
  instanceData: DeploymentInstance[] | null;
  onRefresh: () => void;
  removeInstance: (instanceId: any) => void;
  filteredDataSet?: any;
  elementCount?: any;
  filteredDataCallback: (data: any) => void;
  filtersSelected?: any;
  filtersAppliedCallback: (data: any) => void;
  filters?: any;
  currentPageNumber?: number;
  currentPageSize?: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, sortDirection: string): void;
  applicationId: string | null;
  deploymentId: string | null;
  onRegisterInstance: (instance: DeploymentInstance) => void;
  resourceGroupId: string | null;
  instanceDataLoading: boolean;
}) => {
  const { t } = useTranslation('applicationDeploymentDetails');

  const { onRefresh, removeInstance, readOnly, instanceDataLoading } = props;

  const [showErrorSnackbar, setShowErrorSnackbar] = useState<{
    errorType: string;
    display: boolean;
  }>({ errorType: 'default', display: false });

  const { trackButtonClicked } = useAnalytics();

  const externalInstanceHeaders = [
    {
      key: 'local_ip',
      originalKey: 'local_ip',
      header: t('instances.localIp'),
    },
    {
      key: 'global_ip',
      originalKey: 'global_ip',
      header: t('instances.globalIp'),
    },
    {
      key: 'updated',
      originalKey: 'updated',
      header: t('instances.updated'),
    },
    {
      key: 'deleteInstance',
      originalKey: 'updated',
    },
  ];

  const clusterInstanceHeaders = [
    {
      key: 'pod_name',
      originalKey: 'pod_name',
      header: t('instances.podName'),
    },
    {
      key: 'local_ip',
      originalKey: 'local_ip',
      header: t('instances.localIp'),
    },
    {
      key: 'updated',
      originalKey: 'updated',
      header: t('instances.updated'),
    },
    {
      keu: 'deleteInstance',
      originalKey: 'updated',
    },
  ];

  const setRowsData = () => {
    let formattedRows: {
      id: string;
      local_ip: any;
      global_ip: string;
      updated: any;
      deleteInstance: JSX.Element;
      pod_name: string;
    }[] = [];
    if (props.instanceData && props.instanceData.length === 0) return [];
    if (props.instanceData)
      props.instanceData.map((row: DeploymentInstance) => {
        formattedRows.push({
          id: row.resource_id,
          pod_name: row.pod_name,
          local_ip: row.ip_address,
          global_ip: row.consumer_ip,
          updated: dateUtils.getUserFriendlyDate(row.updated_at),
          deleteInstance: (
            <div className='instance-delete-icon'>
              {' '}
              <div className='icon'>
                <CarbonLink
                  className='delete-link'
                  onClick={() => {
                    trackButtonClicked(
                      analyticsData['Application Deployment Details'].events
                        .deleteInstance.props,
                      analyticsData['Application Deployment Details'].events
                        .deleteInstance.event
                    );
                    removeInstance(row);
                  }}
                >
                  <TrashCan16 className='trash-can-svg' />
                </CarbonLink>
              </div>
            </div>
          ),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noPoliciesIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('instances.emptyState.emptyContainerHeader'),
    emptyStateDescription: t('instances.emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('instances.emptyState.buttonText'),
  };

  const handleCloseErrorBar = () => {
    setShowErrorSnackbar({ errorType: 'default', display: false });
  };

  return (
    <div className='appliction-deployment-instance-card'>
      <div className='header-container'>
        <div className='header'>{t('instances.header')}</div>
      </div>
      <div className='local-ip-and-register'>
        <Row>
          <Column lg={10} md={8}>
            {showErrorSnackbar?.display && (
              <InlineNotification
                onClose={() => handleCloseErrorBar() as any}
                kind={'error'}
                title={
                  showErrorSnackbar?.errorType === '403'
                    ? (t('instances.error.authTitle') as string)
                    : (t('instances.error.title') as string)
                }
                subtitle={
                  showErrorSnackbar?.errorType === '403'
                    ? (t('instances.error.authSubtitle') as string)
                    : (t('instances.error.subtitle') as string)
                }
              />
            )}
          </Column>
        </Row>
        <Row>
          <Column lg={16}>
            <div className='table-container'>
              <GenericTableWithFilters
                id='instance-application-depl-table'
                rows={setRowsData()}
                data={props.filteredDataSet}
                headers={
                  readOnly ? clusterInstanceHeaders : externalInstanceHeaders
                }
                isSortable={false}
                totalElementsCount={props.elementCount ? props.elementCount : 0}
                fullData={props.filteredDataSet}
                onTableRefresh={() => onRefresh()}
                filteredDataCallback={(data: any) =>
                  props.filteredDataCallback(data)
                }
                selectedFiltersVal={props.filteredDataSet as any}
                setFilterApplied={(data: any) =>
                  props.filtersAppliedCallback(data)
                }
                filters={props.filteredDataSet}
                currentPageNumber={props.currentPageNumber}
                currentPageSize={props.currentPageSize}
                onPageChange={(pageData: any) => props.onPageChange(pageData)}
                emptyState={emptyStateData}
                sortRows={(data, sortDirection) =>
                  props.sortRows(data, sortDirection)
                }
                hasFilter={true}
                hasPagination={false}
                tableDataLoading={instanceDataLoading}
              />
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default InstanceApplicationDepl;
