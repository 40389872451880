import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Service, ServiceEndPoint } from '../../models/master';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import images from '../../images/images';
import dateUtils from '../../lib/dates';
import './ServiceEndPointsApplDeployments.scss';
import {
  Button,
  Column,
  Row,
  TextInput,
  Link as CarbonLink,
  ComboBox,
} from 'carbon-components-react';
import { Add16, ErrorFilled16, TrashCan16 } from '@carbon/icons-react';
import InlineNotification from '../../components/Notifications/Inline/Notification';
import { domainNameValidation, ipRegexPattern } from '../../lib/regex';
import { AxiosError } from 'axios';
import { addDeploymentServiceEndPoint } from '../../controllers/applicationApis';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import { FormLabel } from '@carbon/react';

const defaultFormValue = {
  targets: {
    value: '',
    hostname: [],
    ip: [],
    error: false,
    errorMessage: '',
  },
  service_id: {
    value: null,
    error: false,
    errorMessage: '',
  },
};

interface FormData {
  targets: {
    value: string;
    hostname: string[];
    ip: string[];
    error: boolean;
    errorMessage: string;
  };
  service_id: {
    value: Service | null;
    error: boolean;
    errorMessage: string;
  };
}

const requiredFields = ['targets', 'service_id'];

const ServiceEndPointsApplDeployments = (props: {
  serviceEndPointsData: ServiceEndPoint[] | null;
  onRefresh: () => void;
  removeServiceEndPoint: (serviceEndPoint: ServiceEndPoint) => void;
  filteredDataSet?: any;
  elementCount?: any;
  filteredDataCallback: (data: any) => void;
  filtersSelected?: any;
  filtersAppliedCallback: (data: any) => void;
  filters?: any;
  currentPageNumber?: number;
  currentPageSize?: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, sortDirection: string): void;
  applicationId: string | null;
  deploymentId: string | null;
  deploymentEnvId: string | null;
  services: Service[] | null;
  onRegisterServiceEndpoint: (serviceEndPoint: ServiceEndPoint) => void;
  svcEndpointDataLoading: boolean;
  deploymentType: string;
  svcEndpointDeletionError: {
    display: boolean;
    serviceId: string;
  };
  closeDeleteErrorNotification: () => void;
}) => {
  const { t } = useTranslation('applicationDeploymentDetails');
  const {
    onRefresh,
    removeServiceEndPoint,
    closeDeleteErrorNotification,
    deploymentType,
    serviceEndPointsData,
    svcEndpointDeletionError,
    svcEndpointDataLoading,
    services,
  } = props;

  const [formData, setFormData] = useState<FormData>(defaultFormValue);
  const [loading, setLoading] = useState(false);
  const [showNoServicesSnackbar, setShowNoServicesSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState<{
    errorType: string;
    display: boolean;
  }>({ errorType: 'default', display: false });

  const { trackButtonClicked } = useAnalytics();

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    let hostnames: string[] = [];
    let ipAddresses: string[] = [];
    switch (name) {
      case 'targets':
        const ipRegexPatt = ipRegexPattern();
        const hostnameRegexPatt = domainNameValidation();
        const inputArray = value.replace(/\s+/g, '').split(',');
        if (value === '') {
          errorMessage = t('serviceEndPoints.validation.targets.required');
        } else {
          inputArray.forEach((address: string) => {
            if (ipRegexPatt.test(address)) {
              ipAddresses.push(address);
            } else if (hostnameRegexPatt.test(address)) {
              hostnames.push(address);
            } else {
              errorMessage = t(
                'serviceEndPoints.validation.targets.invalidRegex'
              ) as string;
            }
          });
        }
        break;
      case 'service_id':
        errorMessage = !value
          ? t('serviceEndPoints.validation.service_id.required')
          : '';
    }
    return { errorMessage, hostnames, ipAddresses };
  };

  const handleOnChange = (name: string, value: any) => {
    const { errorMessage, hostnames, ipAddresses } = checkFieldValidation(
      name,
      value
    );

    if (name === 'targets') {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          hostname: hostnames,
          ip: ipAddresses,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    }
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      const { errorMessage, hostnames, ipAddresses } = checkFieldValidation(
        field,
        trimmedValue
      );
      if (
        errorMessage ||
        (field === 'targets' &&
          hostnames.length === 0 &&
          ipAddresses.length === 0)
      ) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      trackButtonClicked(
        analyticsData['Application Deployment Details'].events
          .registerServiceEndpoint.props,
        analyticsData['Application Deployment Details'].events
          .registerServiceEndpoint.event
      );
      setLoading(true);
      const data = {
        hostname_targets: formData.targets.hostname.join(','),
        ip_targets: formData.targets.ip.join(','),
        service_id: formData.service_id.value?.resource_id,
        depl_env_id: props.deploymentEnvId,
        deployment_id: props.deploymentId,
        local_service_ip_address: '',
      };

      const serviceEndpointData = await addDeploymentServiceEndPoint(
        props.applicationId,
        props.deploymentId,
        data
      );

      props.onRegisterServiceEndpoint(serviceEndpointData as any);

      setFormData(defaultFormValue);
    } catch (error: any) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setShowErrorSnackbar({ errorType: '403', display: true });
      } else {
        setShowErrorSnackbar({ errorType: 'default', display: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseErrorBar = () => {
    setShowErrorSnackbar({ errorType: 'default', display: false });
  };

  const externalEndpointHeaders = [
    {
      key: 'serviceName',
      originalKey: 'serviceName',
      header: t('serviceEndPoints.serviceName'),
      sort: 'sortByName',
    },
    {
      key: 'targets',
      originalKey: 'targets',
      header: t('serviceEndPoints.localIpAndHostname'),
      sort: 'sortByName',
    },
    {
      key: 'updated',
      originalKey: 'updated',
      header: t('serviceEndPoints.updated'),
      sort: 'sortByName',
    },
    {
      key: 'deleteServiceEndPoint',
      originalKey: 'updated',
    },
  ];

  const clusterEndpointHeaders = [
    {
      key: 'serviceName',
      originalKey: 'serviceName',
      header: t('serviceEndPoints.serviceName'),
      sort: 'sortByName',
    },
    {
      key: 'local_ip',
      originalKey: 'local_ip',
      header: t('serviceEndPoints.localIp'),
      sort: 'sortByName',
    },
    {
      key: 'updated',
      originalKey: 'updated',
      header: t('serviceEndPoints.updated'),
      sort: 'sortByName',
    },
  ];

  const getAvailableServices = () => {
    const serviceIds = serviceEndPointsData?.map(
      (endpoint: ServiceEndPoint) => endpoint.service_id
    );

    return props.services?.filter(
      (svc: Service) => serviceIds?.indexOf(svc.resource_id) === -1
    );
  };

  const getServiceName = (service_id: string) => {
    const service = props.services?.find(
      service => service.resource_id === service_id
    );

    return service ? service.name : '';
  };

  const getHostnameIpAddressDisplayString = (
    hostname_targets: string,
    ip_targets: string
  ) => {
    return `${hostname_targets.split(',').join(', ')}${
      ip_targets && hostname_targets
        ? ',' + ip_targets.split(',').join(', ')
        : ip_targets.split(',').join(', ')
    }`;
  };

  const setRowsData = () => {
    let formattedRows: {
      id: string;
      serviceName: JSX.Element;
      targets?: string;
      local_ip: string;
      updated: any;
      deleteServiceEndPoint: JSX.Element | null;
    }[] = [];
    if (props.serviceEndPointsData && props.serviceEndPointsData.length === 0)
      return [];

    if (props.serviceEndPointsData)
      props.serviceEndPointsData.map((row: ServiceEndPoint) => {
        formattedRows.push({
          id: row.resource_id,
          serviceName: (
            <div className='service-name-container'>
              {svcEndpointDeletionError.display && (
                <ErrorFilled16 className='error-icon' />
              )}
              {getServiceName(row.service_id)}
            </div>
          ),
          targets:
            deploymentType === 'external'
              ? getHostnameIpAddressDisplayString(
                  row.hostname_targets ?? '',
                  row.ip_targets ?? ''
                )
              : '',
          local_ip: row.local_service_ip_address ?? '—',
          updated: dateUtils.getUserFriendlyDate(row.updated_at),
          deleteServiceEndPoint:
            deploymentType === 'cluster' ? null : (
              <div className='instance-delete-icon'>
                <div className='icon'>
                  <CarbonLink
                    className='delete-link'
                    onClick={() => {
                      trackButtonClicked(
                        analyticsData['Application Deployment Details'].events
                          .deleteServiceEndpoint.props,
                        analyticsData['Application Deployment Details'].events
                          .deleteServiceEndpoint.event
                      );
                      removeServiceEndPoint(row);
                    }}
                  >
                    <TrashCan16 className='trash-can-svg' />
                  </CarbonLink>
                </div>
              </div>
            ),
        });

        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noServiceEndpointLargeIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('serviceEndPoints.emptyState.emptyContainerHeader'),
    emptyStateDescription: t(
      'serviceEndPoints.emptyState.emptyContainerDescription'
    ),
    link: '/',
    buttonText: t('serviceEndPoints.emptyState.buttonText'),
  };

  useEffect(() => {
    if (
      services !== null &&
      services.length === 0 &&
      deploymentType === 'external'
    ) {
      setShowNoServicesSnackbar(true);
    } else {
      setShowNoServicesSnackbar(false);
    }
  }, [serviceEndPointsData, deploymentType]);

  return (
    <div className='application-deployment-services-card'>
      <div className='header'>{t('serviceEndPoints.header')}</div>
      {showNoServicesSnackbar && !svcEndpointDataLoading && (
        <Row className='no-services-snackbar-row'>
          <Column>
            <InlineNotification
              className='no-services-snackbar'
              lowContrast
              onClose={() => setShowNoServicesSnackbar(false) as any}
              kind='info'
              title={t('serviceEndPoints.noServices.title')}
              subtitle={t('serviceEndPoints.noServices.subtitle')}
            />
          </Column>
        </Row>
      )}
      {deploymentType === 'external' &&
        !props.svcEndpointDataLoading &&
        !loading && (
          <Row className='add-endpoint-section'>
            <Column lg={7} md={6}>
              <ComboBox
                id='service_id'
                name='service_id'
                className='service_id'
                selectedItem={formData?.service_id?.value}
                onChange={data => {
                  handleOnChange('service_id', data.selectedItem);
                }}
                items={getAvailableServices() ?? []}
                itemToString={item => (item?.name ? item?.name : '')}
                translateWithId={t}
                titleText={t('serviceEndPoints.service_id.labels')}
                placeholder={t('serviceEndPoints.service_id.placeholder')}
                invalid={formData?.service_id?.error}
                invalidText={formData?.service_id?.errorMessage}
                light
              />
            </Column>
            <Column lg={7} md={6}>
              <div className='hostname-input-label-container'>
                <FormLabel>{t('hostnameOrIpAddress' as string)}</FormLabel>
                <a
                  className='learn-more-link'
                  target='_blank'
                  href={`http://ibm.biz/mesh-external-services`}
                >
                  {t('serviceEndPoints.learnMoreLink')}
                </a>
              </div>
              <TextInput
                labelText={''}
                id='targets'
                name={'targets'}
                placeholder={t('inputPlaceholder')}
                autoComplete='off'
                value={formData?.targets?.value}
                helperText={t('serviceEndPoints.ipAddressHelperText')}
                onChange={e => handleOnChange('targets', e.target.value)}
                invalid={formData?.targets?.error}
                invalidText={formData?.targets?.errorMessage}
                light
              />
            </Column>
            <Column lg={2} md={4}>
              <div className='btn-action'>
                <Button
                  className='registerIpAddress'
                  kind='primary'
                  size='md'
                  disabled={
                    !isFormValid() || props.svcEndpointDataLoading || loading
                  }
                  onClick={handleSubmit}
                  renderIcon={Add16}
                >
                  {t('registerButton')}
                </Button>
              </div>
            </Column>
          </Row>
        )}
      {showErrorSnackbar?.display && (
        <Row>
          <Column>
            <InlineNotification
              onClose={() => handleCloseErrorBar() as any}
              kind={'error'}
              title={
                showErrorSnackbar?.errorType === '403'
                  ? (t('serviceEndPoints.error.authTitle') as string)
                  : (t('serviceEndPoints.error.title') as string)
              }
              subtitle={
                showErrorSnackbar?.errorType === '403'
                  ? (t('serviceEndPoints.error.authSubtitle') as string)
                  : (t('serviceEndPoints.error.subtitle') as string)
              }
            />
          </Column>
        </Row>
      )}
      {svcEndpointDeletionError.display && (
        <Row>
          <Column>
            <InlineNotification
              onClose={() => closeDeleteErrorNotification() as any}
              kind={'error'}
              title={t('serviceEndPoints.error.deletionTitle')}
              subtitle={t('serviceEndPoints.error.deletionSubtitle', {
                name: getServiceName(svcEndpointDeletionError?.serviceId),
              })}
            />
          </Column>
        </Row>
      )}
      <div className='table-container'>
        <GenericTableWithFilters
          id='instance-application-depl-table'
          rows={setRowsData()}
          data={props.filteredDataSet}
          headers={
            deploymentType === 'cluster'
              ? clusterEndpointHeaders
              : externalEndpointHeaders
          }
          isSortable={false}
          totalElementsCount={props.elementCount ? props.elementCount : 0}
          fullData={props.filteredDataSet}
          onTableRefresh={() => onRefresh()}
          filteredDataCallback={(data: any) => props.filteredDataCallback(data)}
          selectedFiltersVal={props.filteredDataSet as any}
          setFilterApplied={(data: any) => props.filtersAppliedCallback(data)}
          filters={props.filteredDataSet}
          currentPageNumber={props.currentPageNumber}
          currentPageSize={props.currentPageSize}
          onPageChange={(pageData: any) => props.onPageChange(pageData)}
          emptyState={emptyStateData}
          sortRows={(data, sortDirection) =>
            props.sortRows(data, sortDirection)
          }
          hasFilter={true}
          hasPagination={false}
          tableDataLoading={props.svcEndpointDataLoading || loading}
        />
      </div>
    </div>
  );
};

export default ServiceEndPointsApplDeployments;
