import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddApplicationDeployment.scss';
import { addDeployment } from '../../../controllers/applicationApis';
import { useTranslation } from 'react-i18next';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import AddApplicationDeploymentDetails from './AddApplicationDeploymentDetails/AddApplicationDeploymentDetails';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { getPartition } from '../../../controllers/deploymentEnv';
import {
  ResourceGroup,
  DeploymentEnvironment,
  DeploymentEnvironmentSubtype,
  Deployment,
} from '../../../models/master';
import { AxiosError } from 'axios';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  onAdd?: () => void;
  deploymentEnvData: DeploymentEnvironment[] | null;
  deploymentEnvSubtypesData: DeploymentEnvironmentSubtype[] | null;
  resourceGroupsData: ResourceGroup[] | null;
  triggerRefresh: () => void;
  applicationId: string | null;
  updateApplicationDeployments: (deployment: Deployment) => void;
  appDeployments: Deployment[] | null;
  appNetworkSegmentId: string;
  clusterNetworkSegmentsMap: { [key: string]: string[] };
}

interface AppDeploymentData {
  autoDiscover: boolean | null;
  deplEnvId: string;
  isDiscovered: boolean;
  partitionId?: string;
  resourceGroupId: string;
  unmanaged: boolean;
}

interface Item {
  depEnvid: string;
  type: string;
  resourceGroupId: string;
  autoDiscover?: boolean;
  depEnvName: string;
}

const AddApplicationDeployment: React.FC<Props> = ({
  open,
  onClose,
  deploymentEnvData,
  deploymentEnvSubtypesData,
  resourceGroupsData,
  triggerRefresh,
  applicationId,
  updateApplicationDeployments,
  appDeployments,
  appNetworkSegmentId,
  clusterNetworkSegmentsMap,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('addApplicationDeployment');
  const [appDeploymentData, setAppDeploymentData] = useState<AppDeploymentData>(
    {
      autoDiscover: null,
      deplEnvId: '',
      isDiscovered: false,
      partitionId: '',
      resourceGroupId: '',
      unmanaged: false,
    }
  );
  const notification = useContext(NotificationContext);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [showDuplicateNotification, toggleDuplicateNotification] =
    useState(false);
  const [deplEnvName, setDeplEnvName] = useState('');
  const [selectedDepEnv, setSelectedDepEnv] = useState<Item>({
    depEnvid: '',
    type: '',
    resourceGroupId: '',
    autoDiscover: false,
    depEnvName: '',
  });
  const [changed, setChanged] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const { trackButtonClicked } = useAnalytics();

  const handleSubmit = async () => {
    let partitionName: string;
    let environmentName: string;
    let partition;

    try {
      setLoading(true);
      const appdata = {
        auto_discover: appDeploymentData.autoDiscover,
        depl_env_id: appDeploymentData.deplEnvId,
        is_discovered: appDeploymentData.isDiscovered,
        partition_id: appDeploymentData?.partitionId,
        resource_group_id: appDeploymentData.resourceGroupId,
        unmanaged: appDeploymentData.unmanaged,
        type: 'external',
      };

      const applicationDeployment: Deployment = await addDeployment(
        applicationId,
        appdata
      );

      if (
        applicationDeployment?.depl_env_id &&
        applicationDeployment?.partition_id
      ) {
        partition = await getPartition(
          applicationDeployment.depl_env_id,
          applicationDeployment.partition_id
        );
      }

      partitionName = partition?.name;
      environmentName =
        partitionName != null && partitionName.length > 0
          ? deplEnvName + '/' + partitionName
          : deplEnvName;

      notification.onTrigger('ACTION', {
        title: t('successNotification.title'),
        subtitle: `${t('successNotification.subtitle')}${environmentName}.`,
        action: t('successNotification.button'),
        onActionClick: () => {
          navigate(
            `/applicationDeplDetails?appId=${applicationDeployment.application_id}&deplId=${applicationDeployment.resource_id}`
          );
        },
      });

      updateApplicationDeployments(applicationDeployment);
      handleClose();
      // close modal and fetch new data in parent
    } catch (error: any) {
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';

      const err = error as AxiosError;
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);

        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // return Promise.reject(() => console.log(error));
      }
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      //console.error(err);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (
    type: string,
    autoDiscover: boolean | null,
    deploymentEnvId: string,
    resourceGroupId: string,
    deplEnvName: string,
    partitionId?: string
  ) => {
    setDeplEnvName(deplEnvName);
    setChanged(true);
    if (
      type === 'node' ||
      (type === 'vpc' && partitionId === 'deploy-to-vpc')
    ) {
      setAppDeploymentData(prevState => ({
        ...prevState,
        autoDiscover: autoDiscover,
        deplEnvId: deploymentEnvId,
        partitionId: '',
        resourceGroupId: resourceGroupId,
      }));

      if (
        appDeployments?.some(
          appDeployment =>
            appDeployment.depl_env_id === deploymentEnvId &&
            appDeployment.partition_id === ''
        )
      ) {
        toggleDuplicateNotification(true);
        setChanged(false);
        if (showFailNotification) {
          toggleFailNotification(false);
          setAuthError(false);
        }
      } else {
        toggleDuplicateNotification(false);
        if (showFailNotification) {
          toggleFailNotification(false);
          setAuthError(false);
        }
        setChanged(true);
      }
    } else {
      setAppDeploymentData(prevState => ({
        ...prevState,
        autoDiscover: autoDiscover,
        deplEnvId: deploymentEnvId,
        partitionId: partitionId,
        resourceGroupId: resourceGroupId,
      }));

      if (
        appDeployments?.some(
          appDeployment =>
            appDeployment.depl_env_id === deploymentEnvId &&
            appDeployment.partition_id === partitionId
        )
      ) {
        toggleDuplicateNotification(true);
        setChanged(false);
        if (showFailNotification) {
          toggleFailNotification(false);
          setAuthError(false);
        }
      } else {
        toggleDuplicateNotification(false);
        if (showFailNotification) {
          toggleFailNotification(false);
          setAuthError(false);
        }
        setChanged(true);
      }
    }
  };

  const selectDeploymentEnv = (item: Item) => {
    setSelectedDepEnv(prevState => ({
      ...prevState,
      depEnvid: item.depEnvid,
      type: item.type,
      resourceGroupId: item.resourceGroupId,
      autoDiscover: item.autoDiscover,
      depEnvName: item.depEnvName,
    }));
  };

  const handleClose = () => {
    onClose();
    selectDeploymentEnv({
      depEnvid: '',
      type: '',
      resourceGroupId: '',
      autoDiscover: false,
      depEnvName: '',
    });
    toggleFailNotification(false);
    setAuthError(false);
    toggleDuplicateNotification(false);
    setChanged(false);
  };

  const enableButton = (status: boolean) => {
    setChanged(status);
  };

  const handleCloseErrorBar = () => {
    setAuthError(false);
    toggleFailNotification(false);
  };

  const getFilteredDeploymentEnvs = () => {
    const isDefaultNetworkSegment =
      appNetworkSegmentId === DEFAULT_NETWORK_SEGMENT_ID ||
      appNetworkSegmentId === '';

    const filteredDeploymentEnvData: DeploymentEnvironment[] = [];

    if (Array.isArray(deploymentEnvData)) {
      for (const deploymentEnv of deploymentEnvData) {
        if (
          isDefaultNetworkSegment &&
          (deploymentEnv.type === 'vpc' || deploymentEnv.type === 'node')
        ) {
          filteredDeploymentEnvData.push(deploymentEnv);
        }

        if (deploymentEnv.type === 'cluster') {
          if (
            Array.isArray(clusterNetworkSegmentsMap[deploymentEnv.resource_id])
          ) {
            if (
              clusterNetworkSegmentsMap[deploymentEnv.resource_id].includes(
                appNetworkSegmentId
              )
            ) {
              filteredDeploymentEnvData.push(deploymentEnv);
            }
          } else {
            if (isDefaultNetworkSegment) {
              filteredDeploymentEnvData.push(deploymentEnv);
            }
          }
        }
      }
    }

    return filteredDeploymentEnvData;
  };

  return (
    <div className='add-application-deployment-container'>
      <WideTearsheet
        title={t('title')}
        description={t('description')}
        open={open}
        actions={[
          {
            kind: 'primary',
            label: t('submitButton'),
            onClick: () => {
              trackButtonClicked(
                analyticsData['Application Details'].events.registerDeployment
                  .props,
                analyticsData['Application Details'].events.registerDeployment
                  .event
              );
              handleSubmit();
            },
            disabled: !changed,
            loading: loading,
          },
          {
            kind: 'secondary',
            label: t('cancelButton'),
            onClick: () => {
              handleClose();
            },
          },
        ]}
      >
        {showFailNotification && (
          <InlineNotification
            onClose={() => handleCloseErrorBar as any}
            kind={'error'}
            title={
              authError
                ? (t('failureNotification.authTitle') as string)
                : (t('failureNotification.title') as string)
            }
            subtitle={
              authError
                ? (t('failureNotification.authSubtitle') as string)
                : subTitleErrorMsg.length === 0
                ? (t('failureNotification.subtitle') as string)
                : subTitleErrorMsg
            }
          />
        )}
        {showDuplicateNotification && (
          <InlineNotification
            onClose={() => toggleDuplicateNotification(false) as any}
            kind={'error'}
            title={t('duplicateNotification.title') as string}
            subtitle={t('duplicateNotification.subtitle') as string}
          />
        )}
        {open && (
          <AddApplicationDeploymentDetails
            deploymentEnvData={getFilteredDeploymentEnvs()}
            deploymentEnvSubtypesData={deploymentEnvSubtypesData}
            resourceGroupsData={resourceGroupsData}
            refreshData={triggerRefresh}
            getAppDeploymentData={handleSelect}
            selectedDepEnv={selectedDepEnv}
            handleSelectDepEnv={(item: Item) => selectDeploymentEnv(item)}
            enableButton={(status: boolean) => enableButton(status)}
            appNetworkSegmentId={appNetworkSegmentId}
          />
        )}
      </WideTearsheet>
    </div>
  );
};

export default AddApplicationDeployment;
